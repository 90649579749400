<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
<!--							 <a-select-option :value="''">全部</a-select-option>-->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
			
					<a-form-item class="ui-form__item" label="所属影院" name="cinemaIds">
						<a-select placeholder="请选择" v-model:value="formState.cinemaIds" style="min-width: 180px;max-width: 500px;" mode="multiple" @change="onCinemaChange">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id" > {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
			
			        <a-form-item class="ui-form__item" label="放映时间" name="time" :rules="[{required: true, message: '必选项不允许为空'}]">
						<a-range-picker v-model:value="formState.time" @calendarChange="onCalendarChange" :disabledDate="disabledDate"/>
					</a-form-item>
			
				</a-row>
				<a-row>
					<a-col :span="18">
			            <span v-permission="['report_revenue_seatSale_export']">
							<ExportReport type="seatStatistics" :searchData="searchData"></ExportReport>
						</span>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px;" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			
			<a-table style="margin-top: 10px;" rowKey="id" :columns="columns" :dataSource="list" :pagination="pagination" :scroll="{ x: 1300 }">
				<template #bodyCell="{column, record}">
					<template v-if="column.key === 'date'">
						{{ transDateTime(record.createTime , 1) }}
					</template>
					<template v-if="column.key === 'week'">
						星期{{ ['日', '一', '二', '三', '四', '五', '六'][moment(record.createTime *1000).day()] }}
					</template>
				</template>
				
				<template #summary v-if="list && list.length">
					<a-table-summary-row>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.numberCardTicketNum.toFixed(0) || '' }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.yearCardTicketNum.toFixed(0) || '' }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.tikTokTicketNum.toFixed(0) || '' }}
						</a-table-summary-cell>
						<!-- <a-table-summary-cell>
							合计：{{ total.voucherTicketNum.toFixed(0) || '' }}
						</a-table-summary-cell> -->
						<a-table-summary-cell>
							合计：{{ total.couponTicketNum.toFixed(0) || '' }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.cashTicketNum.toFixed(0) || '' }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.dyCashTicketNum.toFixed(0) || '' }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.totalTicketNum.toFixed(0) || '' }}
						</a-table-summary-cell>
					</a-table-summary-row>
				</template>
			</a-table>
		</a-spin>
	</div>
</template>

<script>
	import ExportReport from '@/components/exportReport/exportReport.vue';
	import { seatStatistics } from '@/service/modules/report.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	export default {
		components: { ExportReport },
		data(){
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					time: [],
					organizationId: '',
					cinemaIds: [0]
				},
				searchData: {},
				list:[],
				columns: [{
					title: '放映时间',
					key: 'date',
					width: 150
				}, {
					title: '星期',
					key: 'week',
					width: 150
				}, {
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '次卡',
					dataIndex: 'numberCardTicketNum'
				}, {
					title: '年卡',
					dataIndex: 'yearCardTicketNum'
				}, 
				{
					title: '抖音券',
					dataIndex: 'tikTokTicketNum'
				},
				// {
				// 	title: '兑换券(旧)',
				// 	dataIndex: 'voucherTicketNum'
				// }, 
				{
					title: '兑换券',
					dataIndex: 'couponTicketNum'
				}, {
					title: '微信现金支付',
					dataIndex: 'cashTicketNum'
				}, {
					title: '抖音现金支付',
					dataIndex: 'dyCashTicketNum'
				}, {
					title: '合计人数',
					dataIndex: 'totalTicketNum'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						// this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						// this.getData();
					}
				},
			}
		},
		created(){
			this.formState.time = [this.moment().startOf('day'), this.moment().endOf('day')];
			this.getOrganizationList();
			this.onSearch();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				delete this.searchData.time;
				if(this.searchData.cinemaIds && this.searchData.cinemaIds.length && this.searchData.cinemaIds.indexOf(0) === -1) {
					this.searchData.cinemaIds = this.searchData.cinemaIds.join(',');
				} else {
					this.searchData.cinemaIds = undefined;
				}
				if (this.formState.time && this.formState.time.length) {
					this.searchData.startTime = this.moment(this.formState.time[0].startOf('day')).unix();
					this.searchData.endTime = this.moment(this.formState.time[1].endOf('day')).unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.searchData = {};
				this.formState.cinemaIds = [0];
				this.cinemaAllList = [];
				this.formState.time = [this.moment().startOf('day'), this.moment().endOf('day')];
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.list = [];
				 this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await seatStatistics({
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.total = {};
						ret.data.forEach(item => {
							this.total.numberCardTicketNum = (this.total.numberCardTicketNum || 0) + item.numberCardTicketNum;
							this.total.yearCardTicketNum = (this.total.yearCardTicketNum || 0) + item.yearCardTicketNum;
							this.total.voucherTicketNum = (this.total.voucherTicketNum || 0) + item.voucherTicketNum;
							this.total.couponTicketNum = (this.total.couponTicketNum || 0) + item.couponTicketNum;
							this.total.cashTicketNum = (this.total.cashTicketNum || 0) + item.cashTicketNum;
							this.total.totalTicketNum = (this.total.totalTicketNum || 0) + item.totalTicketNum;
							this.total.dyCashTicketNum = (this.total.dyCashTicketNum || 0) + item.dyCashTicketNum;
							this.total.tikTokTicketNum = (this.total.tikTokTicketNum || 0) + item.tikTokTicketNum;
						})
						this.list = ret.data;
					}
				} catch (error) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			disabledDate(current) {
				return current && current < this.moment('2022-12-26').startOf('day');
				// if (!this.formState.time || this.formState.time.length === 0) {
				//     return current && current > this.moment().endOf('day');
				// }
			 //    const tooLate = this.formState.time[0] && current.diff(this.formState.time[0], 'days') > 30;
				// const tooEarly = this.formState.time[1] && this.formState.time[1].diff(current, 'days') > 30;
				// const tooCurrent = current && current > this.moment().endOf('day');
			 //    return tooEarly || tooLate || tooCurrent;
			},
			onCinemaChange(keys) {
				if (keys.indexOf(0) !== -1) {
					keys.splice(keys.indexOf(0), 1);
				}
				if (keys.length === 0) {
					keys = [0];
				}
				this.formState.cinemaIds = keys;
			},
			onCalendarChange(val) {
				this.time = val;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
